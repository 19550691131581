import VueRouter, {NavigationGuardNext, RouteConfig, Route, RouteMeta} from "vue-router";
import {
  ResolverBuilder,
  AxRouteConfig,
  AxRouterOptions,
} from "./ax-router.model";
import {Resolver} from "@/api/router/resolver";

/**
 *
 * @param resolveClass
 * @param to
 * @param from
 * @param next
 */
async function applyResolverOnBeforeEnter(
  resolveClass: ResolverBuilder,
  to: Route,
  from: Route,
  next: NavigationGuardNext
): Promise<any> {
  const resolver: Resolver<any> = new resolveClass(to, from);
  const meta: RouteMeta | undefined = to.meta;
  if (meta != null) {
    meta.data = await resolver.resolve();
  }

  next();
}

/**
 *
 * @param routes
 */
function buildRouteBeforeEnterFromResolver(
  routes: AxRouteConfig[]
): RouteConfig[] {
  return routes.map((route) => {
    return Object.assign({}, route, {
      children: !route.children
        ? undefined
        : buildRouteBeforeEnterFromResolver(route.children),
      meta: Object.assign({ resolver: route.resolver }, route.meta),
    });
  });
}

/**
 *
 * @param options
 */
export function createRouter(options: AxRouterOptions): VueRouter {
  const router = new VueRouter(
    Object.assign({}, options, {
      routes: buildRouteBeforeEnterFromResolver(options.routes),
    })
  );
  console.log(
    `Application routes: ${JSON.stringify(
      router.getRoutes().map((r) => r.path),
      null,
      2
    )}`
  );

  router.beforeResolve((to, from, next) => {
    if (to.meta?.resolver) {
      applyResolverOnBeforeEnter(to.meta.resolver, to, from, next);
    } else {
      next();
    }
  });

  return router;
}
