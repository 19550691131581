import { JsonObject, JsonProperty } from "@axional/ax-object-mapper";

@JsonObject("CardListItem")
class CardListItem {
    @JsonProperty("item_title", String)
    item_title: string = "";

    @JsonProperty("item_subtitle", String)
    item_subtitle: string = "";

    @JsonProperty("item_url", String, true)
    item_url: string = "";

    @JsonProperty("item_icon", String)
    item_icon: string = "";

    @JsonProperty("item_icon_color", String)
    item_icon_color: string = "";
}

@JsonObject("LearnCard")
export default class LearnCard {
    @JsonProperty("card_url", String, true)
    card_url: string = "";

    @JsonProperty("card_type", String)
    card_type: string = "";

    @JsonProperty("card_cols", Number)
    card_cols: number = 0;

    // Mark card items as optional (only type=list does have items)
    @JsonProperty("card_items", [CardListItem], true)
    card_items: CardListItem[] = [];
}
