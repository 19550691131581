import { JsonObject, JsonProperty } from "@axional/ax-object-mapper";
import MenuItem                     from "@/components/app-menu/impl/MenuItem";

@JsonObject("MenuPanel")
export default class MenuPanel {

    @JsonProperty("panel_id", Number)
    private readonly m_panel_id: number = 0;

    @JsonProperty("panel_name", String)
    private readonly m_panel_name: string = "";

    @JsonProperty("panel_order", Number)
    private readonly m_panel_order: number = 0;

    @JsonProperty("panel_icon", String, true)
    private readonly m_panel_icon: string | null = null;

    @JsonProperty("panel_items", [MenuItem])
    private m_panel_items: MenuItem[] = [];

    // ----------------------- extras

    private m_expanded: boolean = false;

    /**
     *
     */
    public setup(): void
    {
        this.m_panel_items.forEach(
          (item) => item.setup(this, item)
        );
    }

    // ========================================================================
    // GETTERS/SETTERS
    // ========================================================================

    public getPanelName(): string           { return this.m_panel_name;     }
    public getPanelIcon(): string | null    { return this.m_panel_icon;     }
    public getPanelItems(): MenuItem[]      { return this.m_panel_items;    }


    /**
     * Getter and setter for change the state of the panel
     */
    public get expanded(): boolean
    {
        return this.m_expanded;
    }

    /**
     * Sets the
     * @param expanded
     */
    public set expanded(expanded: boolean)
    {
        this.m_expanded = expanded;
    }

    /**
     * Return menu panel child item names concatenated as string.
     */
    public getItemNames(): string
    {
        const item = this.m_panel_items.map((item: MenuItem) => {
            return item.getItemName();
        });
        return item.join(", ");
    }
}
