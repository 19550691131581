import { JsonObject, JsonProperty } from "@axional/ax-object-mapper";
import LearningSection              from "@/components/app-menu/impl/page/content/LearningSection";
import FeaturesSection              from "@/components/app-menu/impl/page/content/FeaturesSection";
import LearnCard                    from "@/components/app-menu/impl/page/content/LearnCard";
import FeatureCard                  from "@/components/app-menu/impl/page/content/FeatureCard";

@JsonObject("PageContent")
export default class PageContent {
    @JsonProperty("section_learn", LearningSection)
    private readonly m_section_learn: LearningSection | null = null;

    @JsonProperty("section_features", FeaturesSection)
    private readonly section_features: FeaturesSection | null = null;

    /**
     *
     */
    public getLearnSectionTitle(): string | null
    {
        //return this.m_section_learn?.section_title;
        return this.m_section_learn ? this.m_section_learn.section_title : null;
    }

    /**
     *
     */
    public getLearnSectionCards(): LearnCard[]
    {
        //return this.m_section_learn?.section_cards;
        return this.m_section_learn ? this.m_section_learn.section_cards : [];
    }

    /**
     *
     */
    public getFeaturesSectionTitle(): string | null
    {
        //return this.section_features?.section_title;
        return this.section_features ? this.section_features.section_title : null;
    }

    /**
     *
     */
    public getFeaturesSectionCards(): FeatureCard[]
    {
        //return this.section_features?.section_cards;
        return this.section_features ? this.section_features.section_cards : [];
    }
}
