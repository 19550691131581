import { AxRouteConfig }  from "@/api/router/ax-router.model";
import { NotificationsResolver } from "@/pages/notifications/NotificationsResolver";
import { applicationService } from "@/services/ApplicationService";

/**
 *
 */
const routes: Array<AxRouteConfig> = [
  {
    path: '/notifications',
    name: "notifications",
    resolver: NotificationsResolver,
    component: () => import(/* webpackChunkName: "object" */ "@/pages/notifications/NotificationsComponent.vue"),
    beforeEnter(to, from, next) {
      if (!applicationService.isPolicyLoaded()) {
        next(false);
      } else {
        next();
      }
    }
  },
];

export default routes;
