import router from "../routes";
import {
    AxiosResponseHandledError,
    AxiosModule,
    AxiosHandledError
}                       from "@axional/ax-axios";
import { errorDialog }  from "@/components/error-dialog/ErrorDialog";

export const axiosInstance = new AxiosModule.AxiosWrapper({
    /**
     * Default error handler (internalServer error or any other not specifically handled)
     * Called when response status is 500 or the status code received had not callback defined
     *
     * @param err
     */
    onError:(err: AxiosHandledError) => {
        console.warn("SHOW ERROR DIALOG", err);
        errorDialog.show(err);
    },
    /**
     * Handling a 404 (NOTFOUND) depends on the situation.
     *
     * If the 404 is the result of a URL router location GET the we should go to
     * a specific NOTFOUND handler page.
     *
     * In some specific cases we may need to open a error dialog.
     *
     * Optionally a failed delete/update operation can return 410 (GONE)
     *
     * @param err
     */
    onNotFound:(err: AxiosHandledError) => {
        const error = err as AxiosResponseHandledError;
        router.push({name: "notfound"});
    },
    /**
     * Called when response status code is 403
     *
     * @param err
     */
    onForbidden:(err: AxiosHandledError) => {
        const error = err as AxiosResponseHandledError;
        console.warn("REDIRECT TO FORBIDDEN PAGE");
    },
    /**
     * Called when response status code is 404
     *
     * @param err
     */
    onNotAllowed:(err: AxiosHandledError) => {
        console.warn("onNotAllowed");
        errorDialog.show(err);
    },
    /**
     * Called when response status code is 401
     *
     * @param err
     */
    onUnauthorized:(err: AxiosHandledError) => {
        console.warn("SHOW LOGIN DIALOG");
    },
    /**
     * Called when response status code is 406
     *
     * @param err
     */
    onNotAcceptable:(err: AxiosHandledError) => {
        console.warn("onNotAcceptable");
        errorDialog.show(err);
    },
    /**
     * Called when there's a network error and the request could not be performed
     *
     * @param err
     */
    onNetworkError:(err: AxiosHandledError) => {
        console.warn("SHOW NETWORK SNACKBAR");
    },
    /**
     * Called when response status is 500 and the response object contains the key `appErrorCode`.
     * This call is useful when an application throws custom application error codes.
     *
     * @param err
     */
    customErrorHandler(err: AxiosHandledError) {
        console.warn("customErrorHandler");
        errorDialog.show(err);
    }
});
