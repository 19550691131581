import { JsonObject, JsonProperty } from "@axional/ax-object-mapper";
import LearnCard                    from "@/components/app-menu/impl/page/content/LearnCard";

@JsonObject("LearningSection")
export default class LearningSection {
    @JsonProperty("section_title", String)
    section_title: string = "";

    @JsonProperty("section_cards", [LearnCard])
    section_cards: LearnCard[] = [];
}
