import MenuRoot             from "@/components/app-menu/impl/MenuRoot";
import IMenuRoot            from "@/components/app-menu/api/IMenuRoot";
import { ObjectMapper }     from "@axional/ax-object-mapper";

/**
 * Class used to encapsulate underlying logic of menu generation.
 */
export default class MenuBuilder {
    /**
     * Returns a Menu object for the given JSON data (null if deserializer fails).
     *
     * @param data The actual menu JSON data.
     * @param seen_pages An optional array of menu item pages that have already been seen by user.
     */
    public static build(
        data: IMenuRoot,
        seen_pages?: number[]
    ): MenuRoot | null
    {
        let menu: MenuRoot;

        // Map JSON into a new Menu class instance object.
        try {
            const jsonConverter = new ObjectMapper();
            menu = jsonConverter.deserializeObject(data, MenuRoot);
            menu.setup(seen_pages);
            return menu;
        } catch (err) {
            console.error(err);
            return null;
        }
    }
}
