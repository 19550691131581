import { AxRouteConfig } from "@/api/router/ax-router.model";

const routes: Array<AxRouteConfig> = [
  {
    name: "error",
    path: "/error/:error",
    props: route => ({ error: route.params.error }),
    component: () => import(/* webpackChunkName: "object" */ "@/pages/error/error.component.vue"),
  }
];

export default routes;