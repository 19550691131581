/**
 *
 * A reusable lock.
 *
 * The function getLock() returns a promise that blocks until release() is called
 *
 * Useful for wait for getPolicy
 *
 */
export class Lock<T> {
	/**
	 *
	 * @private
	 */
	private readonly m_lock:Promise<T>;

	/**
	 *
	 * @private
	 */
	private m_resolver: ((value: T) => void) | undefined;

	/**
	 *
	 * @private
	 */
	private m_rejecter: ((err: Error) => void) | undefined;

	/**
	 *
	 */
	public constructor() {
		this.m_lock = new Promise<T>(((resolve, reject) => {
			this.m_resolver = resolve;
			this.m_rejecter = reject;
		}));
	}

	/**
	 *
	 */
	public getLock():  Promise<T> {
		return this.m_lock;
	}

	/**
	 *
	 * @param value
	 */
	public release(value: T): void {
		this.m_resolver?.(value);
	}

	/**
	 *
	 * @param err
	 */
	public reject(err: Error): void {
		this.m_rejecter?.(err);
	}
}