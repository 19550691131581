/**
 * Wrapper class for "wic_portal.portal_user" table.
 */
import {axiosInstance} from "@/plugins/axios-instance";
import {API_PREFIX_MAIN} from "@/constants";
import vuetify from "@/plugins/vuetify";
import {logService} from "@/api/logger/Logger";

class User {
  private user_code!: string;
  private user_lang!: string;
  private user_mail!: string;
  private user_theme!: boolean;

  public setUser(
    user_code: string,
    user_lang: string,
    user_mail: string,
    user_dark_theme: boolean
  )
  {
    this.user_code = user_code;
    this.user_lang = user_lang;
    this.user_mail = user_mail;
    this.user_theme = user_dark_theme;
  }

  public toString(): string
  {
    return `user_code: ${this.user_code}, user_lang: ${this.user_lang}, user_mail: ${this.user_mail}, user_theme: ${this.user_theme}`
  }

  public getUserInitials(): string {
    return this.user_code.slice(0, 1).toUpperCase();
  }

  public getUserCode(): string {
    return this.user_code;
  }

  public getUserLang(): string {
    return this.user_lang;
  }

  public getUserMail(): string {
    return this.user_mail;
  }

  public getUserTheme(): boolean {
    return this.user_theme;
  }

  public async toggleTheme(): Promise<void>
  {
    this.user_theme = !this.user_theme;
    try {
      await this.__update();
      vuetify.framework.theme.dark = this.user_theme;
      logService.log(`${User.name} - toggle dark theme. Is dark=${Boolean(this.user_theme)}`);
    } catch (e) {
      logService.log(`${User.name} - error toggling dark theme. ${e}`);
    }
  }

  private async __update(): Promise<void>
  {
    try {
      await axiosInstance.put(`${API_PREFIX_MAIN}/user`, {
        user_lang: this.user_lang,
        user_theme: Number(this.user_theme),
      });
    } catch(e) {
      console.error("Failed to update user information: ", e.message)
    }
  }
}

export const userService = new User();