import Vue          from "vue";
import VueI18n      from "vue-i18n";
import { messages } from "../assets/labels/messages";

Vue.use(VueI18n);

// Create VueI18n instance with options
export default new VueI18n({
    locale: 'en', // set locale
    messages: messages, // set locale messages
});
