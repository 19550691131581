import { JsonObject, JsonProperty } from "@axional/ax-object-mapper";
import PageHeader                   from "@/components/app-menu/impl/page/header/PageHeader";
import PageContent                  from "@/components/app-menu/impl/page/content/PageContent";

@JsonObject("MenuItemPage")
export default class MenuItemPage {

    @JsonProperty("page_header", PageHeader)
    private readonly page_header: PageHeader | null = null;

    @JsonProperty("page_content", PageContent, true)
    private readonly page_content: PageContent | null = null;

    /**
     * Returns the page header object.
     */
    public getPageHeader(): PageHeader | null
    {
        return this.page_header;
    }

    /**
     * Returns the page content object.
     */
    public getPageContent(): PageContent | null
    {
        return this.page_content;
    }

    /**
     * Returns the optional page title.
     */
    public getPageTitle(): string | undefined
    {
        return this.page_header?.getHeaderTitle();
    }

    /**
     * Returns the optional page gradient color.
     */
    public getPageColor(): string | undefined
    {
        return this.page_header?.getHeaderGradientColor();
    }
}
