// Main API (wic_portal)
// This prefix is used to request main application endpoints defined in wic_portal
// This are endpoints that should not be modified nor overridden by a custom wic, since
// this are used to build the basic application architecture.
export const API_PREFIX_MAIN = process.env.VUE_APP_API_PREFIX_MAIN;

// This prefix is used to intercept custom application endpoint calls
// (defined in application menu)
export const API_PREFIX_APP = process.env.VUE_APP_API_PREFIX_CUSTOM;

// API URL to get application menu
export const API_PREFIX_MENU = process.env.VUE_APP_API_PREFIX_MENU;

// Holds the main proxy name (proxy1)
// Notice we have two proxies:
// 1. "proxy1" to intercept main application endpoints (defined in wic_portal)
// 2. "proxy2" to intercept custom application endpoints (defined in custom wic)
export const VUE_APP_MAIN_PROXY_NAME = process.env.VUE_APP_MAIN_PROXY_NAME;
