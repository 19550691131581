// Ready translated locale messages
export const messages = {
  en: {
    ID_SEARCH: 'Search',
    ID_GO_BACK: "Go back",
    ID_SIGN_OUT: 'Sign out',
    ID_DARK_THEME: 'Dark theme',
    ID_NOTIFICATIONS: 'Notifications',
    ID_NOTIFICATIONS_DES: "You don't have any notifications",
    ID_NOTIFICATIONS_SEE_ALL: 'See all',
    ID_NOTIFICATIONS_ALL: 'All notifications',
    ID_NOTIFICATIONS_SEEN: 'Seen',
    ID_NOTIFICATIONS_NOT_SEEN: 'Not seen',
    ID_NOT_FOUND_MESSAGE: "404",
    ID_NOT_FOUND_MESSAGE_DES: "Sorry, but the page you're looking for is not found. Please make sure you have typed the correct URL",
  },
  es: {
    ID_SEARCH: 'Buscar',
    ID_GO_BACK: "Vuelve atrás",
    ID_SIGN_OUT: 'Cerrar sesión',
    ID_DARK_THEME: 'Modo oscuro',
    ID_NOTIFICATIONS: 'Notificaciones',
    ID_NOTIFICATIONS_DES: "No tienes ninguna notificación",
    ID_NOTIFICATIONS_SEE_ALL: 'Ver todas',
    ID_NOTIFICATIONS_ALL: 'Todas las notificaciones',
    ID_NOTIFICATIONS_SEEN: 'Vistas',
    ID_NOTIFICATIONS_NOT_SEEN: 'No visto',
    ID_NOT_FOUND_MESSAGE: "404",
    ID_NOT_FOUND_MESSAGE_DES: "Lo sentimos, pero no se encuentra la página que estás buscando. Asegúrese de haber escrito la URL correcta.",
  },
  ca: {
    ID_SEARCH: 'Buscar',
    ID_GO_BACK: "Torna enrere",
    ID_SIGN_OUT: 'Tanca la sessió',
    ID_DARK_THEME: 'Tema fosc',
    ID_NOTIFICATIONS: 'Notificacions',
    ID_NOTIFICATIONS_SEE_ALL: 'Veure totes',
    ID_NOTIFICATIONS_DES: "No teniu cap notificació",
    ID_NOTIFICATIONS_ALL: 'Totes les notificacions',
    ID_NOTIFICATIONS_SEEN: 'Vist',
    ID_NOTIFICATIONS_NOT_SEEN: 'No vist',
    ID_NOT_FOUND_MESSAGE: "404",
    ID_NOT_FOUND_MESSAGE_DES: "Ho sentim, però la pàgina que cerqueu no es troba. Assegureu-vos que heu escrit l’URL correcte",
  },
  de: {
    ID_SEARCH: 'Suchen',
    ID_GO_BACK: "Geh zurück",
    ID_SIGN_OUT: 'Abmelden',
    ID_DARK_THEME: 'Dunkles Design',
    ID_NOTIFICATIONS: 'Benachrichtigungen',
    ID_NOTIFICATIONS_DES: "Sie haben keine Benachrichtigungen",
    ID_NOTIFICATIONS_SEE_ALL: 'Alles sehen',
    ID_NOTIFICATIONS_ALL: 'Alle meldingen',
    ID_NOTIFICATIONS_SEEN: 'Gezien',
    ID_NOTIFICATIONS_NOT_SEEN: 'Niet gezien',
    ID_NOT_FOUND_MESSAGE: "404",
    ID_NOT_FOUND_MESSAGE_DES: 'Es tut uns leid, aber die gesuchte Seite wurde nicht gefunden. Bitte stellen Sie sicher, dass Sie die richtige URL eingegeben haben'
  },
  it: {
    ID_SEARCH: 'Cercare',
    ID_GO_BACK: "Torna indietro",
    ID_SIGN_OUT: 'Esci',
    ID_DARK_THEME: 'Tema scuro',
    ID_NOTIFICATIONS: 'Notifiche',
    ID_NOTIFICATIONS_DES: "Non hai notifiche",
    ID_NOTIFICATIONS_SEE_ALL: 'Vedi tutto',
    ID_NOTIFICATIONS_ALL: "Tutte le notifiche",
    ID_NOTIFICATIONS_SEEN: 'Visto',
    ID_NOTIFICATIONS_NOT_SEEN: 'Non visto',
    ID_NOT_FOUND_MESSAGE: "404",
    ID_NOT_FOUND_MESSAGE_DES: "Siamo spiacenti, ma la pagina che stai cercando non è stata trovata. Assicurati di aver digitato l'URL corretto",
  },
  fr: {
    ID_SEARCH: 'Chercher',
    ID_GO_BACK: "Retourner",
    ID_SIGN_OUT: 'Se déconnecter',
    ID_DARK_THEME: 'Thème foncé',
    ID_NOTIFICATIONS: 'Notifications',
    ID_NOTIFICATIONS_DES: "Vous n'avez aucune notification",
    ID_NOTIFICATIONS_SEE_ALL: 'Voir tout',
    ID_NOTIFICATIONS_ALL: "Toutes les notifications",
    ID_NOTIFICATIONS_SEEN: 'Vue',
    ID_NOTIFICATIONS_NOT_SEEN: 'Pas vu',
    ID_NOT_FOUND_MESSAGE: "404",
    ID_NOT_FOUND_MESSAGE_DES: "Désolé, mais la page que vous recherchez est introuvable. Veuillez vous assurer que vous avez tapé la bonne URL.",
  },
}
