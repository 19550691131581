import { AxRouteConfig }  from "@/api/router/ax-router.model";

const routes: Array<AxRouteConfig> = [
  {
    path: '*',
    name: "notfound",
    component: () => import(/* webpackChunkName: "notfound" */ "@/pages/notfound/notfound.component.vue"),
  }
];

export default routes;
