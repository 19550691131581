import FeatureCard                  from "@/components/app-menu/impl/page/content/FeatureCard";
import { JsonObject, JsonProperty } from "@axional/ax-object-mapper";

@JsonObject("FeaturesSection")
export default class SectionLearn {
    @JsonProperty("section_title", String)
    section_title: string = "";

    @JsonProperty("section_cards", [FeatureCard])
    section_cards: FeatureCard[] = [];
}
