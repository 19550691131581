import { JsonObject, JsonProperty } from "@axional/ax-object-mapper";

@JsonObject("PageHeader")
export default class PageHeader {
    @JsonProperty("header_title", String)
    private readonly m_header_title: string = "";

    @JsonProperty("header_subtitle", String)
    private readonly m_header_subtitle: string = "";

    @JsonProperty("header_button", String, true)
    private readonly m_header_button: string | null = null;

    //@JsonProperty("header_button", HeaderButton, true) // Mark button as optional
    //private readonly m_header_button: HeaderButton | null = null;

    @JsonProperty("header_image", String)
    private readonly m_header_image: string = "";

    @JsonProperty("header_primary_color", String)
    private readonly m_header_primary_color: string = "";

    @JsonProperty("header_gradient_color", String)
    private readonly m_header_gradient_color: string = "";

    /**
     * Returns the header title.
     */
    public getHeaderTitle(): string
    {
        return this.m_header_title;
    }

    /**
     * Returns the header subtitle.
     */
    public getHeaderSubtitle(): string
    {
        return this.m_header_subtitle;
    }

    /**
     * Returns the header image.
     */
    public getHeaderImage(): string
    {
        return this.m_header_image;
    }

    /**
     * Returns the header primary color.
     */
    public getHeaderPrimaryColor(): string
    {
        return this.m_header_primary_color;
    }

    /**
     * Returns the header gradient color.
     */
    public getHeaderGradientColor(): string
    {
        return this.m_header_gradient_color;
    }

    /**
     * Returns whether the header has a configured button or not.
     */
    public hasHeaderButton(): boolean
    {
        return this.m_header_button != undefined;
    }

    /**
     * Returns the header button text (if any).
     */
    public getHeaderButtonText(): string | null
    {
        return this.m_header_button;
    }
}
