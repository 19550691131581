import MenuItem from "./MenuItem";

export default class MenuItemTabs {
    private readonly m_panelName: string;
    private readonly m_itemName: string;
    private readonly m_items: MenuItem[];

    /**
     *
     * @param panelName
     * @param itemName
     * @param items
     */
    constructor(panelName: string, itemName: string, items: MenuItem[]) {
        this.m_panelName  = panelName;
        this.m_itemName   = itemName;
        this.m_items      = items;
    }

    public getPanelName(): string       { return this.m_panelName;          }
    public getItemName(): string        { return this.m_itemName;           }
    public hasItems(): boolean          { return this.m_items.length > 0;   }
    public getItems(): MenuItem[]       { return this.m_items;              }

    /**
     *
     * @param itemId
     */
    public getTabIndex(itemId: number): number
    {
        return this.m_items.findIndex((item) => item.getItemId() == itemId)
    }
}
