import { JsonObject, JsonProperty }     from "@axional/ax-object-mapper";
import { Component, VueConstructor }    from "vue";
import { RouteConfig }                  from "vue-router";
import router                           from "../../../routes";
import MenuPanel                        from "./MenuPanel";
import MenuItemTabs                     from "./MenuItemTabs";
import MenuItemPage                     from "./page/MenuItemPage";

export enum MenuRouteType {
    /**
     * Standard route
     */
    ROUTE_TYPE_PUSH,
    /**
     * Route URL points to an external source: want to change page
     */
    ROUTE_TYPE_FOLLOW,
    /**
     * Route URL points to an external source: want to open new window
     */
    ROUTE_TYPE_WINDOW
}

@JsonObject("MenuItem")
export default class MenuItem {

    @JsonProperty("item_id", Number)
    private readonly m_item_id: number = 0;

    @JsonProperty("item_parent_id", Number)
    private readonly m_item_parent_id: number = 0;

    @JsonProperty("item_order", Number)
    private readonly m_item_order: number = 0;

    @JsonProperty("item_name", String)
    private readonly m_item_name: string = "";

    @JsonProperty("item_url", String)
    private m_item_url: string = "";

    @JsonProperty("item_tabs", Number, true)
    private readonly m_item_tabs: number = 0;

    @JsonProperty("item_icon", String, true)
    private readonly m_item_icon: string | null = null;

    @JsonProperty("item_child_name", String, true)
    private readonly m_item_child_name: string | null = null;

    @JsonProperty("item_child_url", String, true)
    private readonly m_item_child_url: string | null = null;

    @JsonProperty("item_children", [MenuItem], true)
    private readonly m_item_children: MenuItem[] = [];

    @JsonProperty("item_page_id", Number, true)
    private readonly m_item_page_id: number = 0;

    @JsonProperty("item_page", MenuItemPage, true)
    private readonly m_item_page: MenuItemPage | null = null;

    // ----------------------- extras

    /**
     * TODO: explain
     */
    private m_parent_panel: MenuPanel | null = null;

    /**
     * TODO: explain!
     */
    private m_parent_item: MenuItem | null = null;

    /**
     * TODO: explain
     */
    private m_route_type: MenuRouteType = MenuRouteType.ROUTE_TYPE_PUSH;

    /**
     * TODO: explain
     */
    private m_route_key: string | null = null;

    /**
     * TODO: explain
     */
    private m_render_component: Component | null = null;

    /**
     * TODO: explain
     */
    private m_seen_pages: number[] = [];

    // ========================================================================
    // GETTERS/SETTERS
    // ========================================================================

    public getItemId(): number              { return this.m_item_id;                    }
    public getItemName(): string            { return this.m_item_name;                  }
    public getItemUrl(): string             { return this.m_item_url;                   }
    public getItemChildUrl(): string | null { return this.m_item_child_url;             }
    public getItemIcon(): string | null     { return this.m_item_icon;                  }
    public getRouteType(): MenuRouteType    { return this.m_route_type;                 }
    public getItemPageId(): number          { return this.m_item_page_id;               }
    public hasItems(): boolean              { return this.m_item_children.length > 0;   }


    public setup(panel: MenuPanel, item: MenuItem) {
        // Sets the item parent panel.
        this.m_parent_panel = panel;

        // Sets the parent menu item.
        this.getItemChildren().forEach((child) => {
            child.m_parent_item = item;
            child.setParentItem(item);
        });

        this.getItemChildren().sort(
          (a,b) => (a.m_item_order > b.m_item_order) ? 1 : ((b.m_item_order > a.m_item_order) ? -1 : 0)
        )
    }

    /**
     * Sets the item parent panel.
     *
     * @param panel The parent menu panel.
     */
    public setParentPanel(panel: MenuPanel): void
    {
        this.m_parent_panel = panel;
    }

    /**
     * Returns the parent menu item.
     */
    public getParentItem(): MenuItem | null
    {
        return this.m_parent_item;
    }

    /**
     * Sets the parent menu item.
     *
     * @param item The parent menu item.
     */
    public setParentItem(item: MenuItem | null): void
    {
        this.getItemChildren().forEach((child) => {
            child.m_parent_item = item;
            child.setParentItem(item);
        });
    }

    /**
     * Stores the list of seen pages id's.
     *
     * @param pages The list of seen pages.
     */
    public setSeenPages(pages: number[]): void
    {
        this.m_seen_pages = pages;
    }

    /**
     * Marks a page as seen.
     *
     * @param pageId The page id to be marked as seen.
     */
    public markPageAsSeen(pageId: number): void
    {
        this.m_seen_pages.push(pageId);
    }

    /**
     * Check if any of the children items is selected,
     * in that case this item has to be selected too.
     *
     * @param url
     */
    public isSomeChildSelected(url: string): boolean
    {
        return this.m_item_children.some(
            (item) => item.getItemUrl() == url
        );
    }

    /**
     * Returns the list of item children items.
     */
    public getItemChildren(): MenuItem[]
    {
        return this.m_item_children;
    }

    /**
     * Returns whether the item has a page header defined.
     */
    public hasItemPageHeader(): boolean
    {
        return this.m_item_page != null && this.m_item_page.getPageHeader() != undefined;
    }

    /**
     * Returns whether the item has a page defined.
     */
    public hasItemPageContent(): boolean
    {
        return this.m_item_page != null && !this.m_seen_pages.includes(this.m_item_page_id) && this.m_item_page.getPageContent() != undefined ;
    }

    /**
     * Returns the item page.
     */
    public getItemPage(): MenuItemPage | null
    {
        return this.m_item_page;
    }

    /**
     * Return the children of this MenuItems to be displayed as tabs.
     */
    public getTabItems(item: MenuItem): MenuItemTabs | null
    {
        // const parentPanel = item.m_parent_panel;
        // if (parentPanel)
        //     return new MenuItemTabs(parentPanel.getPanelName(), item.m_item_name, item.m_item_children);
        // else
        //     return null;
        const parentPanel = item.m_parent_panel;
        if (item.m_item_tabs) {
            // 1: It's an item with tabs
            // Ex: "Consulta de comandes"
            // This code is never executed cause menu always tries to select the
            // first item of a tab.
            if (parentPanel)
                return new MenuItemTabs(parentPanel.getPanelName(), item.m_item_name, item.m_item_children);
        } else {
            // 2: Check if we belong to panel whose parent is in tabs mode
            //    Ex: Consulta de comandes
            //    we are clicking on "Situacio", "Resum", etc.
            //
            //    Get the parent panel
            const parentItem = item.m_parent_item;
            // Recursively go to parent snd check if it's a tab on "Situacio" -> "Consulta comandes"
            if (parentItem)
                return this.getTabItems(parentItem);
        }
        return null;
    }

    /**
     * Generate menu item route
     */
    public toRoute(): RouteConfig
    {
        // ====================================================
        // A menu item can have URLs with default query params:
        // For example: "/wishlist?viewType=list"
        // When generating a route for this menu item, we don't
        // want the path to contain the query params.
        //
        // So, here we remove query parameters from URL
        //
        // Original URL             : "/wishlist?viewType=list"
        // URL with no query params : "/wishlist"
        // ====================================================
        this.m_item_url = this.m_item_url.split('?')[0];

        return {
            path: this.m_item_url,
            name: this.m_item_name || "",
            component: this.m_render_component as VueConstructor,
            meta: {
                title: this.m_item_name,
                // toolbarColor: pageHeader ? pageHeader.color : null
            }
        };
    }

    /**
     * Performs the corresponding route redirection when menu item is clicked.
     */
    public doAction(): void
    {
        if (this.m_route_key != null) {
            const key_value = router.currentRoute.params[this.m_route_key];
            // IN  /projects/:project_id
            // OUT /projects/44
            let toPath = this.m_item_url.replace(/:\w+/gi, key_value);

            if (router.currentRoute.path !== toPath) {
                router.push({
                    name: this.m_item_name || "",
                    params: { key_name: key_value.toString() }
                });
            }
        } else {
            if (router.currentRoute.path !== this.m_item_url) {
                // 1. Store original route path
                let newRoutePath = this.m_item_url;

                // ------------------------------------------------------------
                // WARNING:
                // Items that have nested menu items need to redirect to
                // first menu item route.
                // /direct -> /direct/situacio_total
                // ------------------------------------------------------------
                // 2. Check if we have nested items.
                // If so, get first child route path.
                if (this.hasItems()) {
                    // The menu item "/direct" has three nested items:
                    // 1. /direct/situacio_total
                    // 2. /direct/situacio
                    // 3. /direct/situacio_soci
                    // When we click on menu item "/direct" we need to skip route push since the item is only used to group
                    // nested items. So what we need to do, is to redirect to first nested item path.
                    // IN  -> /direct
                    // OUT -> /direct/situacio_total
                    newRoutePath = this.m_item_children[0].m_item_url;
                }

                // 3. Extract query params object from URL. (Query params are injected in router push action).
                const queryParams = MenuItem.__extractQueryParamsFromURL(newRoutePath);

                // ====================================================
                // A menu item can have URLs with default query params:
                // For example: "/wishlist?viewType=list"
                // When generating a route for this menu item, we don't
                // want the path to contain the query params.
                //
                // So, here we remove query parameters from URL
                //
                // Original URL             : "/wishlist?viewType=list"
                // URL with no query params : "/wishlist"
                //
                // Use routePath: "/wishlist" instead of
                // routeURL: "/wishlist?viewType=list" if URL has params
                // ====================================================
                if (Object.keys(queryParams).length > 0) {
                    newRoutePath = this.m_item_url;
                }

                // 4. Perform corresponding router action according to the type of route.
                switch(this.m_route_type) {
                    case MenuRouteType.ROUTE_TYPE_FOLLOW:
                        window.location.href = newRoutePath;
                        break;
                    case MenuRouteType.ROUTE_TYPE_WINDOW:
                        window.open(newRoutePath);
                        break;
                    case MenuRouteType.ROUTE_TYPE_PUSH:
                    default:
                        router.push({
                            path: newRoutePath,
                            query: queryParams
                        });
                        break;
                }
            }
        }
    }

    // ========================================================================
    // PRIVATE
    // ========================================================================

    /**
     * Extracts query params from a given URL.
     *
     * 1. DecodeURI: abc=foo&def=[asf]&xyz=5
     * 2. Escape quotes: same, as there are no quotes
     * 3. Replace &: abc=foo","def=[asf]","xyz=5
     * 4. Replace =: abc":"foo","def":"[asf]","xyz":"5
     * 5. Surround with curlies and quotes: {"abc":"foo","def":"[asf]","xyz":"5"}
     *
     * IN: abc=foo&def=%5Basf%5D&xyz=5
     * OUT:
     * {
     *     abc: 'foo',
     *     def: '[asf]',
     *     xyz: 5
     * }
     *
     */
    private static __extractQueryParamsFromURL(url: string): Record<string, string>
    {
        let queryParams: Record<string, string> = {};

        // 1. Create an anchor tag to use the property called search
        let anchor = document.createElement('a');

        // 2. Assigning url to href of anchor tag
        anchor.href = url;

        // 3. Search property returns the query string of url
        let queryStrings = anchor.search.substring(1);

        // 5. Check if query string has params.
        if (queryStrings !== "") {
            let params = queryStrings.split('&');
            for (let i = 0; i < params.length; i++) {
                const pair = params[i].split('=');
                queryParams[pair[0]] = decodeURIComponent(pair[1]);
            }
            return queryParams;
        }
        // 6. Return empty query params object when URL has no params.
        return {};
    }
}
