import { logService } from "@/api/logger/Logger";
import { axiosInstance } from "@/plugins/axios-instance";
import { API_PREFIX_MAIN } from "@/constants";
import { Subject } from "@/api/observer/Subject";
import { Observer } from "@/api/observer/Observer";

class NotificationsService implements Subject {
  /**
   *
   * @private
   */
  private observers: Observer[];

  /**
   *
   */
  public constructor() {
    this.observers = [];
  }

  /**
   *
   */
  public async getPageData() {
    const { data } = await axiosInstance.get(`${API_PREFIX_MAIN}/notifications`);
    logService.log(`${NotificationsService.name} - get notifications list`);
    return data;
  }

  /**
   *
   * @param observer
   */
  public attach(observer: Observer): void {
    if (!this.observers.includes(observer)) {
      this.observers.push(observer);
    }
  }

  /**
   *
   * @param observer
   */
  public detach(observer: Observer): void {
    const observerIndex = this.observers.indexOf(observer);
    if (observerIndex !== -1) {
      this.observers.splice(observerIndex, 1);
    }
  }

  /**
   *
   */
  public notify(): void {
    this.observers.forEach((observer) => observer.update());
  }
}

export const notificationsService = new NotificationsService();