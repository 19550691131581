import { AxRouteConfig }      from "@/api/router/ax-router.model";
import { ObjectResolver }     from "@/pages/object/ObjectResolver";
import { applicationService } from "@/services/ApplicationService";

/**
 *
 */
const routes: Array<AxRouteConfig> = [
  {
    path: '/:url+', // If you need to match routes with multiple sections like /first/second/third, you should mark a param as repeatable with * (0 or more) and + (1 or more):
    name: "object",
    resolver: ObjectResolver,
    component: () => import(/* webpackChunkName: "object" */ "@/pages/object/ObjectComponent.vue"),
    beforeEnter(to, from, next) {
      if (!applicationService.isPolicyLoaded()) {
        next(false);
      } else {
        next();
      }
    }
  },
];

export default routes;
