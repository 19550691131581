import Vue                          from 'vue';
import VueRouter, { RouteConfig }   from 'vue-router';
import { createRouter }             from './api/router/ax-router';
import { loadingService }           from "@/services/LoadingService";

// Routes
import notificationsRoutes          from "@/pages/notifications/NotificationsRoutes";
import objectRoutes                 from "@/pages/object/ObjectRoutes";
import errorRoutes                  from "@/pages/error/error.routes";
import notfoundRoutes               from "@/pages/notfound/notfound.routes";
import { applicationService }       from "@/services/ApplicationService";

Vue.use(VueRouter);

/**
 * Using the webpackChunkName we achieve Route level code-splitting.
 * This generates a separate chunk (name.[hash].js) for this route
 * which is lazy-loaded when the route is visited.
 */
const routes: RouteConfig[] = [
    {
        path: '/',
        name: 'homepage',
        component: () => import(/* webpackChunkName: "homepage"*/ '@/pages/home/HomeView.vue'),
        beforeEnter(to, from, next) {
            console.log("applicationService.isPolicyLoaded(): ", applicationService.isPolicyLoaded())
            if (!applicationService.isPolicyLoaded()) {
                next(false);
            } else {
                next();
            }
        }
    },
    {
        path: '/news/:news_id',
        name: 'news',
        props: true,
        component: () => import(/* webpackChunkName: "news"*/ './pages/home/HomeCardDetailsView.vue'),
        beforeEnter(to, from, next) {
            if (!applicationService.isPolicyLoaded()) {
                next(false);
            } else {
                next();
            }
        }
    },
    ...notificationsRoutes,
    ...objectRoutes,
    ...errorRoutes,
    ...notfoundRoutes
];

/**
 * Export a new route instance with the configured routes.
 * When using history mode, the URL will look "normal," e.g. http://oursite.com/user/id
 * Here comes a problem, though: Since our app is a single page client side app, without
 * a proper server configuration, the users will get a 404 error if they access
 * http://oursite.com/user/id directly in their browser.
 */
const router = createRouter({
    // mode: "history",
    // base: "/",
    routes
});

/**
 * Start loading service (progress linear) before entering a new route.
 */
router.beforeEach(async (to, from, next) => {
    // Await until policy has been fetched before entering any route.
    await applicationService.getPolicyLock();

    // TODO: explain
    if (applicationService.isPolicyLoaded()) {
        loadingService.start();
    }
    next();
});

/**
 * Stop loading service (progress linear) after entering a route.
 */
router.afterEach(() => {
    loadingService.done();
});


export default router;
