import { Route } from "vue-router";

export abstract class Resolver<T> {
  protected readonly to: Route;
  protected readonly from: Route;

  constructor(to: Route, from: Route) {
    this.to = to;
    this.from = from;
  }

  abstract resolve(): Promise<T>;
}
