class Logger {
  /**
   *
   */
  private readonly enabled: boolean;

  /**
   *
   * @param enabled
   */
  public constructor(enabled: boolean = true)
  {
    this.enabled = enabled;
  }

  /**
   *
   * @param msg
   */
  public log(msg: string)
  {
    this.enabled && console.log(msg);
  }

  /**
   *
   * @param msg
   */
  public info(msg: string): void
  {
    this.enabled && console.info(msg);
  }

  /**
   *
   * @param msg
   */
  public warn(msg: string): void
  {
    this.enabled && console.warn(msg);
  }

  /**
   *
   * @param msg
   */
  public error(msg: string): void
  {
    this.enabled && console.error(msg);
  }
}

export const logService = new Logger();