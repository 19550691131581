import NProgress from "nprogress";
NProgress.configure({ showSpinner: false });

export class LoadingService {
  done() {
    NProgress.done();
  }

  start() {
    NProgress.start();
  }
}

export const loadingService = new LoadingService();