import { API_PREFIX_MAIN, API_PREFIX_MENU } from "@/constants";
import { axiosInstance }                    from "@/plugins/axios-instance";
import router                               from "@/routes";
import { Ref, ref }                         from "@/plugins/composition-api";
import vuetify                              from "@/plugins/vuetify";
import i18n                                 from "@/plugins/vue-i18n";
import { IPolicy }                          from "@/services/ApplicationModel";
import { userService }                      from "@/services/user/User";
import { logService }                       from "@/api/logger/Logger";
import { Lock }                             from "@/api/RouterLock";
import MenuBuilder                          from "@/components/app-menu/impl/MenuBuilder";
import MenuRoot                             from "@/components/app-menu/impl/MenuRoot";

class ApplicationService {
  /**
   *
   * @private
   */
  private static readonly DEFAULT_APP_LOCALE: string = "en";

  /**
   *
   * @private
   */
  private m_policyLock: Lock<any> = new Lock();

  /**
   * Holds the application policy(user_lang, menu_uuid...) data.
   */
  private m_policy: IPolicy | null;

  /**
   * TODO: remove -> move to menuService!
   */
  private m_appMenu: Ref<MenuRoot | null>;

  /**
   * Controls whether the policy request is loading or not.
   */
  private m_policyLoaded: Ref<boolean>;

  /**
   * Controls whether the policy request is loading or not.
   */
  private m_policyLoading: Ref<boolean>;

  /**
   * Controls whether the menu request is loading or not.
   */
  private m_menuLoading: Ref<boolean>;

  /**
   *
   */
  public constructor()
  {
    this.m_policyLoaded   = ref(false);
    this.m_policyLoading  = ref(true);
    this.m_menuLoading    = ref(false);
    this.m_policy         = null;

    // TODO: remove -> move to menuService!
    this.m_appMenu = ref(null);
  }

  public async getPolicyLock(): Promise<any>
  {
    return this.m_policyLock.getLock();
  }

  /**
   * Returns whether the policy request is loading or not.
   */
  public isPolicyLoaded(): boolean
  {
    return this.m_policyLoaded.value;
  }

  /**
   * Returns whether the policy request is loading or not.
   */
  public isPolicyLoading(): boolean
  {
    return this.m_policyLoading.value;
  }

  // ==========================================================================
  // APPLICATION MENU
  // ==========================================================================

  /**
   * Returns whether the menu request is loading or not.
   */
  public isMenuLoading(): boolean
  {
    return this.m_menuLoading.value;
  }

  /**
   * TODO: remove... mode to menuService
   */
  public getApplicationMenu(): MenuRoot | null
  {
    return this.m_appMenu.value;
  }

  /**
   * TODO: remove... mode to menuService
   */
  public toggleMenuVisibility(): void
  {
    this.m_appMenu.value?.toggleDrawerState();
  }

  // ==========================================================================
  // APPLICATION HOME UUID
  // ==========================================================================

  /**
   * Returns the application home UUID.
   * It is used to retrieve home data.
   */
  public getAppHomeId(): string | undefined
  {
    return this.m_policy?.home_uuid;
  }

  // ==========================================================================
  // APPLICATION POLICY DATA FETCH
  // ==========================================================================

  /**
   * Fetches the application policy.
   */
  public async fetchPolicy(): Promise<void>
  {
    try {
      this.m_policyLoading.value = true;

      // Fetch application policy.
      const response = await axiosInstance.get(`${API_PREFIX_MAIN}/policy`);

      // Store policy data.
      this.__setPolicyData(response.data);

      // Release policy lock
      this.m_policyLock.release(true);
      this.m_policyLoaded.value = true;

      console.warn("isPolicyLoaded()", this.isPolicyLoaded());

      // Await policy fetch before asking for menu (menu UUID comes in app policy).
      await this.__fetchAppMenu();
    } catch (e) {
      logService.error(`${ApplicationService.name} - error getting policy data: ${e}`);

      // Release policy lock in order to redirect to error route
      this.m_policyLock.release(e);
      this.m_policyLock.reject(e);

      // Redirect to error route
      await router.push({name: "error", params: {error: e}});

    } finally {
      this.m_policyLoading.value = false;
    }
  }

  // ==========================================================================
  // PRIVATE
  // ==========================================================================

  /**
   * Fetches the application menu.
   */
  private async __fetchAppMenu(): Promise<void>
  {
    if (this.m_policy && this.m_policy.menu_uuid) {
      this.m_menuLoading.value = true;
      try {
        const response = await axiosInstance.get(
          `${API_PREFIX_MENU}/${this.m_policy.menu_uuid}/${this.m_policy.user_lang}`
        );
        // Map JSON into a new Menu class instance object.
        const menu_seen_pages = this.m_policy.menu_seen_pages.map((p: { page_id: any; }) => p.page_id);
        this.m_appMenu.value = MenuBuilder.build(response.data, menu_seen_pages);
      } catch (err) {
        console.error("Error fetching application menu: ", err);
      } finally {
        this.m_menuLoading.value = false;
      }
    }
  }

  /**
   * Sets the application policy data.
   *
   * @param data The data received from policy API request.
   */
  private __setPolicyData(data: IPolicy): void
  {
    // Store policy data
    this.m_policy = data;

    // Create a new application user
    userService.setUser(
      data.user_code,
      data.user_lang,
      data.user_mail,
      data.user_theme
    );

    logService.log(`${ApplicationService.name} - set application user: ${JSON.stringify(userService.toString())}`);

    // Set application dark theme
    vuetify.framework.theme.dark = data.user_theme;

    // Change default i18n locale with user's one.
    i18n.locale = data.user_lang || ApplicationService.DEFAULT_APP_LOCALE;
  }
}

export const applicationService = new ApplicationService();