import {axiosInstance} from "@/plugins/axios-instance";
import {API_PREFIX_APP} from "@/constants";
import {Ref, ref} from "@/plugins/composition-api";

/**
 *
 */
class ObjectService {

  /**
   *
   * @private
   */
  private loading: Ref<boolean>;

  /**
   *
   * @private
   */
  private error: Ref<Error | null>;

  /**
   *
   */
  public constructor() {
    this.loading = ref(false);
    this.error = ref(null);
  }

  /**
   * Returns if we're still loading data or not.
   */
  public hasError(): boolean {
    return this.error.value != null;
  }

  /**
   * Returns if we're still loading data or not.
   */
  public isLoading(): boolean {
    return this.loading.value;
  }

  /**
   *
   * @param value
   */
  public setLoading(value: boolean): void {
    this.loading.value = value;
  }

  /**
   *
   * @param path
   */
  public async getPageData(path: string): Promise<any> {
    const url = `${API_PREFIX_APP}${path}`;
    try {
      this.loading.value = true;
      return await axiosInstance.get(url);
    } catch (e) {
      console.error("Error getting object page data: ", e);
      this.error.value = e;
    } finally {
      this.loading.value = false;
    }
  }
}

export const objectService = new ObjectService();