import { JsonObject, JsonProperty } from "@axional/ax-object-mapper";

@JsonObject("FeatureCard")
export default class LearnCard {
    @JsonProperty("card_title", String)
    readonly card_title: string = "";

    @JsonProperty("card_subtitle", String)
    readonly card_subtitle: string = "";

    @JsonProperty("card_cols", Number)
    readonly card_cols: number = 0;

    @JsonProperty("card_image", String)
    readonly card_image: string = "";

    @JsonProperty("card_bg_color", String)
    readonly card_bg_color: string = "";

    @JsonProperty("card_url", String, true)
    card_url: string = "";
}
