import {Ref, ref} from "@/plugins/composition-api";

class ErrorDialog {
	private m_dialog: Ref<boolean> = ref(false);
	private m_error: Ref<Error | null> = ref(null);

	public constructor() {
	}

	public getError(): Error | null {
		return this.m_error.value;
	}

	public isVisible(): boolean {
		return this.m_dialog.value;
	}

	public show(error: Error) {
		this.m_error.value = error;
		this.m_dialog.value = true;
	}

	public hide() {
		this.m_dialog.value = false;
	}
}

export const errorDialog = new ErrorDialog();