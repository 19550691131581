import {JsonObject, JsonProperty} from "@axional/ax-object-mapper";
import MenuPanel from "@/components/app-menu/impl/MenuPanel";
import {RouteConfig} from "vue-router";
import MenuItem, {MenuRouteType} from "@/components/app-menu/impl/MenuItem";
import vuetify from "@/plugins/vuetify";

@JsonObject("MenuRoot")
export default class MenuRoot {
  @JsonProperty("root_title", String)
  private readonly root_title: string = "";

  @JsonProperty("root_subtitle", String)
  private readonly root_subtitle: string = "";

  @JsonProperty("root_primary_color", String)
  private readonly root_primary_color: string | null = "";

  @JsonProperty("root_secondary_color", String, true)
  private readonly root_secondary_color: string | null = null;

  @JsonProperty("root_text_color", String)
  private readonly root_text_color: string | null = null;

  @JsonProperty("root_image_small", String)
  private readonly root_image_small: string = "";

  @JsonProperty("root_image_large", String)
  private readonly root_image_large: string = "";

  // @JsonProperty("root_temporary", Boolean)
  // private readonly root_temporary: boolean = false;

  @JsonProperty("panels", [MenuPanel])
  private readonly m_panels: MenuPanel[] = [];

  // ------------- Extras

  /**
   * Controls whether the component is visible or hidden.
   */
  private m_isVisible: boolean = false;

  /**
   * Condenses navigation drawer width, also accepts the .sync modifier. With this,
   * the drawer will re-open when clicking it.
   */
  private m_miniVariant: boolean = false;

  /**
   * Makes drawer sit above its application and uses a scrim (overlay) to darken the background.
   */
  private m_temporary: boolean = false;

  // ========================================================================
  // GETTERS / SETTERS
  // ========================================================================

  public getMenuTitle(): string {
    return this.root_title;
  }

  public getMenuSubtitle(): string {
    return this.root_subtitle;
  }

  public getPrimaryColor(): string | null {
    return this.root_primary_color;
  }

  public getSecondaryColor(): string | null {
    return this.root_secondary_color;
  }

  public getTextColor(): string | null {
    return this.root_text_color;
  }

  public getMenuPanels(): MenuPanel[] {
    return this.m_panels;
  }

  public getMenuLogoSmall(): string {
    return "data:image/png;base64," + this.root_image_small;
  }

  public getMenuLogoLarge(): string {
    return "data:image/png;base64," + this.root_image_large;
  }

  public isVisible(): boolean {
    return this.m_isVisible;
  }

  public isMiniVariant(): boolean {
    return this.m_miniVariant;
  }

  /**
   *
   * @param seen_pages
   */
  public setup(seen_pages?: number[]): void {
    // Setup application colors.
    if (this.root_primary_color) {
      vuetify.framework.theme.themes.light.primary  = this.root_primary_color;
      vuetify.framework.theme.themes.dark.primary   = this.root_primary_color;
    }

    if (this.root_secondary_color) {
      vuetify.framework.theme.themes.light.secondary  = this.root_secondary_color;
      vuetify.framework.theme.themes.dark.secondary   = this.root_secondary_color;
    }

    // Call post-constructor setup method for every menu panel.
    this.m_panels.forEach((panel) => {
        panel.setup();
        if (seen_pages) {
          panel.getPanelItems().forEach((item) => {
            item.setSeenPages(seen_pages);
          });
        }
      }
    );

    // Generate dynamic routes
    // this.__getDynamicMenuRoutes().forEach(
    //     (route) => router.addRoute(route)
    // );
  }

  /**
   * Sets the drawer visibility state.
   *
   * @param visible
   */
  public setIsVisible(visible: boolean): void {
    this.m_isVisible = visible;
  }

  /**
   * Sets whether the menu is temporary or not.
   *
   * @param temporary
   */
  public setIsTemporary(temporary: boolean): void {
    this.m_temporary = temporary;
  }

  /**
   * DEPRECATED... should come from database
   * Sets whether the menu is temporary or not.
   * @param mini
   */
  public setIsMini(mini: boolean): void {
    this.m_miniVariant = mini;
  }

  /**
   * Toggles menu mini variant.
   */
  public toggleDrawerState(): void {
    if (this.m_temporary)
      this.m_isVisible = !this.m_isVisible;
    else
      this.m_miniVariant = !this.m_miniVariant;
  }

  /**
   * Return the MenuItem for a given routePath
   *
   * @param path
   */
  public getMenuItem(path: string): MenuItem | null {
    return this.__getMenuItem(path, this.m_panels);
  }

  // ========================================================================
  // PRIVATE
  // ========================================================================

  /**
   * Returns the list of dynamic menu routes.
   */
  private __getDynamicMenuRoutes(): RouteConfig[] {
    const routes: RouteConfig[] = [];
    this.__toRoute(this.m_panels, routes);
    //console.log("__getDynamicMenuRoutes: ", routes);
    return routes;
  }

  /**
   * Build menu routes
   *
   * @param panels
   * @param routes
   */
  private __toRoute(panels: Array<MenuPanel>, routes: Array<RouteConfig>): void {
    for (const panel of panels) {
      this.__toRouteItems(panel.getPanelItems(), routes);
    }
  }

  /**
   *
   * @param items
   * @param routes
   */
  private __toRouteItems(items: MenuItem[], routes: RouteConfig[]): void {
    for (const item of items) {
      // Avoid to add NON VUE routes (external URLs)
      if (item.getRouteType() !== MenuRouteType.ROUTE_TYPE_PUSH)
        continue;

      // Skip  route generation of items whose parent has a page defined
      // Notice that when an item has a page defined, we don't go to the actual page,
      // we display the item page. This means that if we have an item that has children,
      // this routes should not be generated since the parent page will not be displayed until the page has been marked as seen.
      const parent = item.getParentItem();
      if (parent && parent.hasItemPageContent()) {
        const parentItem = parent ? parent.getItemName() : null
        console.log("Skipping item '%s' route generation because parent item '%s' has a page defined.", item.getItemName(), parentItem);
        continue;
      }

      routes.push(item.toRoute());
      if (item.hasItems()) {
        this.__toRouteItems(item.getItemChildren(), routes);
      }
    }
  }

  /**
   * Return menu item object for a given name
   *
   * @param path
   * @param panels
   * @private
   */
  private __getMenuItem(path: string, panels: MenuPanel[]): MenuItem | null {
    for (const item of this.__getMenuPanelItems()) {
      if (item.getItemUrl() == path)
        return item;
    }
    return null;
  }

  /**
   *
   */
  private __getMenuPanelItems(): MenuItem[] {
    const list: MenuItem[] = [];
    for (const panel of this.m_panels) {
      for (const item of this.__getMenuItemItems(panel.getPanelItems()))
        list.push(item);
    }
    return list;
  }

  /**
   *
   * @param items
   */
  private __getMenuItemItems(items: MenuItem[]): MenuItem[] {
    const list: MenuItem[] = [];
    for (const item of items) {
      list.push(item);
      if (item.hasItems()) {
        for (const item2 of this.__getMenuItemItems(item.getItemChildren()))
          list.push(item2)
      }
    }
    return list;
  }
}
